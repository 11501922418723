// extracted by mini-css-extract-plugin
export var searchPage = "SearchPage-module--searchPage--3smEo";
export var content = "SearchPage-module--content--3SbzY";
export var resultsHeader = "SearchPage-module--resultsHeader--3ONPR";
export var resultsData = "SearchPage-module--resultsData--3E4Ed";
export var resultsInfo = "SearchPage-module--resultsInfo--2hu0r";
export var resultsResearch = "SearchPage-module--resultsResearch--1xKtZ";
export var resultsHeading = "SearchPage-module--resultsHeading--2si0u";
export var galleryWrapper = "SearchPage-module--galleryWrapper--3127D";
export var collectionContainer = "SearchPage-module--collectionContainer--2UBP5";
export var productCard = "SearchPage-module--productCard--3iEh0";
export var productImg = "SearchPage-module--productImg--3WbK4";
export var productInfo = "SearchPage-module--productInfo--2oQOz";
export var productName = "SearchPage-module--productName--1t8b_";
export var productMemberPrice = "SearchPage-module--productMemberPrice--35FOA";
export var selectCollection = "SearchPage-module--selectCollection--1uHFU";
export var collectionItem = "SearchPage-module--collectionItem--2fHS_";
export var footerContainer = "SearchPage-module--footerContainer--1FKK9";