import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { connect } from 'react-redux'
import ProgressiveImage from 'react-progressive-image'
import { convertToUSD, trackingGA } from '@merchx-v2/helpers'
import { useSiteMetadata } from 'hooks/use-site-metadata'
import { navigate } from 'utils/navigate'
import { Header, Footer, FacebookPixels, Searchable, SEO } from 'components'
import { selectProducts } from 'state/HomePage/reducer'
import * as styles from './SearchPage.module.scss'

const HomePage = ({ products, search }) => {
  const { landing, shared } = useSiteMetadata()

  const campaignName = landing.CAMPAIGN.name

  useEffect(() => {
    window.scrollTo(0, 0)
    trackingGA()
  }, [])

  if (!search) {
    return navigate("/")
  }

  return (
    <div className={styles.searchPage}>
      <Header />
      <SEO pathname='/search' title={campaignName} />
      <FacebookPixels id={shared.FACEBOOK_PIXEL_ID} event='CompleteRegistration' />

      <div className={styles.content}>
        <div className={styles.galleryWrapper}>
          <div className={styles.collectionContainer}>
            <div className={styles.resultsHeader}>
              <div className={styles.resultsData}>
                <h3 className={styles.resultsInfo}>
                  {campaignName} / Search / {search}
                </h3>
                <h2 className={styles.resultsHeading}>Results for “{search}”</h2>
              </div>
              <div className={styles.resultsResearch}>
                <p>Didn’t find what you are looking for?</p>
                <Link to='/'>Search something else</Link>
              </div>
            </div>

            <div className={styles.selectCollection}>
              <Searchable searchText={search} searchSource={products} keys={['productDisplayName', 'collectionName', 'name', 'tags', 'displayName', 'description']}>
                {({ data }) => (
                  <>
                    {data.map(product => (
                      <Link className={styles.collectionItem} to={`/products/${(product.hasCustomSeo && product.seoUrl) || product.productId}`} key={product.id}>
                        <div className={styles.productCard}>
                          <ProgressiveImage src={product.previewImageUrl}>{src => <img src={src} alt={product.name} className={styles.productImg} />}</ProgressiveImage>

                          <div className={styles.productInfo}>{product.collectionName}</div>

                          <div className={styles.productName}>{product.productDisplayName}</div>
                          <div className={styles.productName}>${convertToUSD(product.price)}</div>
                          <div className={styles.productMemberPrice}>${convertToUSD(product.memberPrice)} (member price)</div>
                        </div>
                      </Link>
                    ))}
                  </>
                )}
              </Searchable>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footerContainer}>
        <Footer />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  products: selectProducts(state)
})

export default connect(mapStateToProps)(HomePage)
